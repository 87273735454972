import { Button, Icon, ITableColumn, useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { reinviteSuppliers } from "api/projectSuppliersApi";
import useProject from "features/project/hooks/useProject";
import SelectSupplierColumn from "features/project/monitor/components/SelectSupplierColumn";
import SubmissionIconsColumn from "features/project/monitor/components/SubmissionIconsColumn";
import SubmissionStatusColumn from "features/project/monitor/components/SubmissionStatusColumn";
import SupplierNameColumn from "features/project/monitor/components/SupplierNameColumn";
import TotalContractValueColumn from "features/project/monitor/components/TotalContractValueColumn";
import { IProjectInformation } from "features/project/types";
import useCurrentUser from "hooks/useCurrentUser";
import usePolicies from "hooks/usePolicies";
import { createUseStyles } from "react-jss";
import { buildRoute, Route } from "routes/helpers/RoutesHelper";
import { ProjectParams } from "routes/routePaths/ProjectRoutePaths";
import currencyService from "services/currencyService";
import dateTimeService from "services/dateTimeService";
import { ITheme } from "styles/themes/types";
import { BasicCompanyDto } from "types/dtos/company/BasicCompanyDto";
import { SubmissionAdditionalInformationDto } from "types/dtos/projects/supplierSubmissions/SubmissionAdditionalInformationDto";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import ProjectType from "types/enums/projects/ProjectType";
import SupplierSubmissionDisplayStatus from "types/enums/supplierSubmissions/SupplierSubmissionDisplayStatus";
import SupplierSubmissionStatus from "types/enums/supplierSubmissions/SupplierSubmissionStatus";

const useStyles = createUseStyles((theme: ITheme) => ({
    button: {
        ...theme.typography.headings.h4,
        background: "inherit",
        border: "none",
        cursor: "pointer",
    },
    container: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing.medium,
        justifyContent: "flex-end",
        width: "100%",
    },
}));

interface IUseMonitorColumnsProps {
    project: IProjectInformation;
    canAwardSupplier: boolean;
    setDocumentSupplier: React.Dispatch<React.SetStateAction<BasicCompanyDto | undefined>>;
    setAdditionalInformation: React.Dispatch<React.SetStateAction<SubmissionAdditionalInformationDto | undefined>>;
    navigationRoute: Route<ProjectParams>;
    editTcv?: (supplier: SupplierSubmissionResponseDto) => void;
    selectSupplier?: (supplier: SupplierSubmissionResponseDto) => void;
    hasUserCompletedScoring?: boolean;
    navigateToSingleSupplierScoring?: (id: string) => void;
    toggleExpand?: (id: string) => void;
    isExpanded?: boolean;
    isProjectManager?: boolean;
    isScoringCompleteForUser?: boolean;
    hasDeadlinePassed?: boolean;
}

const useMonitorColumns = (props: IUseMonitorColumnsProps) => {
    const toast = useToaster();
    const { t } = useTranslation();
    const classes = useStyles();

    const { myUuid, myCompanyUuid, userIsMaistro } = useCurrentUser();
    const { projectInformation } = useProject();

    const [companyUuid, setCompanyUuid] = useState(myCompanyUuid);
    const [userUuid, setUserUuid] = useState(myUuid);

    const {
        project,
        canAwardSupplier,
        setDocumentSupplier,
        setAdditionalInformation,
        navigationRoute,
        editTcv,
        selectSupplier,
        hasUserCompletedScoring,
        navigateToSingleSupplierScoring,
        toggleExpand,
        isExpanded,
        isProjectManager,
        isScoringCompleteForUser,
        hasDeadlinePassed,
    } = props;

    useEffect(() => {
        if (userIsMaistro) {
            setCompanyUuid(projectInformation.clientCompanyUuid);
            setUserUuid(projectInformation.sponsorUserUuid ?? "");
        }
    }, [projectInformation.clientCompanyUuid, projectInformation.sponsorUserUuid, userIsMaistro]);

    const { getProjectSpendLimitPolicies, unapprovedTieringStatuses } = usePolicies(companyUuid, userUuid);

    const showTcv = useMemo(() => {
        const projectSpendLimitPolicies = getProjectSpendLimitPolicies(project.type, project.budgetCurrency);
        return project.type === ProjectType.Tender && projectSpendLimitPolicies.length > 0;
    }, [getProjectSpendLimitPolicies, project.budgetCurrency, project.type]);

    const openDocumentDrawer = useCallback(
        (supplier: BasicCompanyDto) => {
            setDocumentSupplier(supplier);
        },
        [setDocumentSupplier],
    );

    const openAdditionalInformationDrawer = useCallback(
        (supplier: SupplierSubmissionResponseDto) =>
            setAdditionalInformation({
                supplierName: supplier._embedded.supplierCompanyName,
                additionalInformation: supplier.supplierNotes ?? "",
            }),
        [setAdditionalInformation],
    );

    const reinviteSupplier = useCallback(
        async (supplier: SupplierSubmissionResponseDto) => {
            const response = await reinviteSuppliers(project.uuid, supplier.supplierCompanyUuid, myUuid);

            if (response.status !== 204 && response.status !== 404) {
                toast.error(
                    t("projectSummary.api.reinviteSupplierError", {
                        supplierName: supplier._embedded.supplierCompanyName,
                    }),
                );
                return;
            }

            toast.success(
                t("projectSummary.api.reinviteSupplierSuccess", {
                    supplierName: supplier._embedded.supplierCompanyName,
                }),
            );
        },
        [myUuid, project.uuid, t, toast],
    );

    const toDisplayStatus = (status: SupplierSubmissionStatus) => {
        switch (status) {
            case SupplierSubmissionStatus.NotInterested:
                return SupplierSubmissionDisplayStatus.NotInterested;
            case SupplierSubmissionStatus.Withdrawn:
                return SupplierSubmissionDisplayStatus.Withdrawn;
            case SupplierSubmissionStatus.Successful:
                return SupplierSubmissionDisplayStatus.Successful;
            case SupplierSubmissionStatus.Shortlisted:
            case SupplierSubmissionStatus.Submitted:
            case SupplierSubmissionStatus.Unsuccessful:
                return SupplierSubmissionDisplayStatus.Received;
            case SupplierSubmissionStatus.Invited:
                return SupplierSubmissionDisplayStatus.Invited;
            case SupplierSubmissionStatus.Responding:
                return SupplierSubmissionDisplayStatus.Responding;
            default:
                return SupplierSubmissionDisplayStatus.Waiting;
        }
    };

    const handleSelectSupplier = useCallback(
        (supplier: SupplierSubmissionResponseDto) => {
            if (
                selectSupplier === undefined ||
                toDisplayStatus(supplier.status) !== SupplierSubmissionDisplayStatus.Received
            ) {
                return;
            }

            selectSupplier(supplier);
        },
        [selectSupplier],
    );

    const columns: ITableColumn[] = useMemo(() => {
        const columnConfiguration: ITableColumn[] = [
            {
                position: 1,
                priorityLevel: 1,
                label: t("monitorScreen.table.supplierName"),
                name: "supplierCompanyName",
                minWidth: 150,
                contentWrap: true,
                render: (supplier: SupplierSubmissionResponseDto) => (
                    <SupplierNameColumn
                        name={supplier._embedded.supplierCompanyName}
                        companyUuid={supplier.supplierCompanyUuid}
                        matchRating={supplier._included.matchRating}
                        navigationState={{
                            route: buildRoute(navigationRoute, {
                                projectUuid: project.uuid,
                            }),
                        }}
                    />
                ),
            },
            {
                position: 2,
                priorityLevel: 8,
                label: t("monitorScreen.table.quoteStatus"),
                name: "status",
                minWidth: 130,
                render: (supplier: SupplierSubmissionResponseDto) => (
                    <SubmissionStatusColumn
                        status={toDisplayStatus(supplier.status)}
                        testid={`${supplier.supplierCompanyUuid}-status`}
                    />
                ),
            },
            {
                position: 3,
                priorityLevel: 5,
                label: t("monitorScreen.table.responseDate"),
                name: "responseDate",
                minWidth: 130,
                render: (supplier: SupplierSubmissionResponseDto) => (
                    <div>
                        {toDisplayStatus(supplier.status) === SupplierSubmissionDisplayStatus.Received ||
                        toDisplayStatus(supplier.status) === SupplierSubmissionDisplayStatus.Successful
                            ? dateTimeService.toShortDateFormat(supplier._embedded.submissionDate)
                            : "-"}
                    </div>
                ),
            },
        ];

        if (project.type === ProjectType.Quote) {
            columnConfiguration.push({
                position: 4,
                priorityLevel: 4,
                label: t("monitorScreen.table.supplierPrice"),
                name: "supplierPricing",
                minWidth: 100,
                tooltip: t("monitorScreen.tooltips.supplierPrice"),
                contentWrap: true,
                render: (supplier: SupplierSubmissionResponseDto) => (
                    <div>
                        {currencyService.toDisplayFormat(
                            supplier.supplierPricing.simplePrice.ccy,
                            supplier.supplierPricing.simplePrice.price,
                        )}
                    </div>
                ),
            });
        }

        if (canAwardSupplier && hasUserCompletedScoring) {
            if (showTcv) {
                columnConfiguration.push({
                    position: 5,
                    priorityLevel: 6,
                    label: t("monitorScreen.table.buyerPrice"),
                    name: "buyerPricing",
                    minWidth: 200,
                    contentWrap: true,
                    tooltip: t("monitorScreen.tooltips.buyerPrice"),
                    render: (supplier: SupplierSubmissionResponseDto) => (
                        <TotalContractValueColumn supplier={supplier} editTcv={editTcv} />
                    ),
                });
            }

            columnConfiguration.push({
                position: 6,
                priorityLevel: 3,
                label: t("monitorScreen.table.quoteScore"),
                name: "scoring",
                minWidth: 100,
                labelWrap: false,
                contentWrap: true,
                tooltip:
                    project.type === ProjectType.Tender
                        ? t("monitorScreen.tooltips.tenderScore")
                        : t("monitorScreen.tooltips.quoteScore"),
                render: (supplier: SupplierSubmissionResponseDto) => <div>{supplier.scoring ?? "-"}</div>,
            });
        }

        columnConfiguration.push({
            position: 7,
            priorityLevel: 9,
            name: "additionalInformation",
            minWidth: 150,
            render: (supplier: SupplierSubmissionResponseDto) => (
                <SubmissionIconsColumn
                    supplier={supplier}
                    deadlineHasPassed={dateTimeService.dateIsInPast(project.tenderResponseDeadline)}
                    tieringWarning={unapprovedTieringStatuses.includes(supplier.supplierTieringStatus)}
                    openAdditionalInformationDrawer={openAdditionalInformationDrawer}
                    openDocumentDrawer={openDocumentDrawer}
                    reinviteSupplier={reinviteSupplier}
                />
            ),
        });

        if (canAwardSupplier) {
            columnConfiguration.push({
                position: 8,
                priorityLevel: 2,
                name: "actions",
                minWidth: 160,
                contentWrap: true,
                render: (supplier: SupplierSubmissionResponseDto) => (
                    <SelectSupplierColumn supplier={supplier} handleSelectSupplier={handleSelectSupplier} />
                ),
            });
        }

        if (
            (!isScoringCompleteForUser || isProjectManager) &&
            hasDeadlinePassed &&
            navigateToSingleSupplierScoring &&
            toggleExpand
        ) {
            columnConfiguration.push({
                position: 8,
                priorityLevel: 9,
                name: "scoreSingleSupplierButton",
                minWidth: 130,
                className: "alignRight",
                render: (supplier: SupplierSubmissionResponseDto) =>
                    toDisplayStatus(supplier.status) === SupplierSubmissionDisplayStatus.Received && (
                        <div className={classes.container}>
                            <Button
                                label={t("monitorScreen.scoreTenderResponses")}
                                onClick={() => navigateToSingleSupplierScoring(supplier.supplierCompanyUuid)}
                                size="small"
                                testid="score-responses-button"
                            />
                            <button
                                className={classes.button}
                                onClick={() => toggleExpand(supplier.supplierCompanyUuid)}
                                type="button"
                                aria-label="expand-button"
                                data-testid="expand-button"
                            >
                                <Icon icon={isExpanded ? faChevronUp : faChevronDown} />
                            </button>
                        </div>
                    ),
            });
        }
        return columnConfiguration;
    }, [
        t,
        project.type,
        project.uuid,
        project.tenderResponseDeadline,
        canAwardSupplier,
        hasUserCompletedScoring,
        isScoringCompleteForUser,
        isProjectManager,
        hasDeadlinePassed,
        navigateToSingleSupplierScoring,
        toggleExpand,
        navigationRoute,
        showTcv,
        editTcv,
        unapprovedTieringStatuses,
        openAdditionalInformationDrawer,
        openDocumentDrawer,
        reinviteSupplier,
        handleSelectSupplier,
        classes.container,
        classes.button,
        isExpanded,
    ]);

    return {
        columnsConfiguration: columns,
    };
};

export default useMonitorColumns;
