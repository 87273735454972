import { Button, Copy, RichTextEditorField, TextField } from "@maistro/components";
import { Form, Formik } from "formik";
import { TFunction } from "i18next";
import { isEmpty } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import DisplayFileUploadField from "features/files/DisplayFileUploadFields";
import useReduxSelector from "hooks/useReduxSelector";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";
import CurrencyType from "types/enums/CurrencyType";

const useStyles = createUseStyles((theme: ITheme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.large,
    },
    control: {
        display: "flex",
        flexDirection: "column",
    },
    buttonControl: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.large,
    },
    deadlineExpired: {
        color: theme.colors.error,
        fontWeight: 500,
        lineHeight: "24px",
    },
    documentUpload: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.xSmall,
    },
}));

interface ISupplierResponseFormProps {
    supplierPrice?: number;
    supplierNotes?: string;
    currency: CurrencyType;
    submit: (supplierPrice: number, supplierNotes?: string) => void;
    deadlineHasPassed: boolean;
    files?: FileDto[];
    setFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    removeFile: (file: FileDto | null) => void;
    fileIsUploading: boolean;
    setFileIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
}

const validationSchema = (t: TFunction<"translation", undefined>) =>
    Yup.object({
        supplierPrice: Yup.number()
            .required(t("supplierResponse.supplierPrice.required"))
            .min(1, t("supplierResponse.supplierPrice.required")),
        supplierNotes: Yup.string(),
    });

const SupplierResponseForm: React.FC<ISupplierResponseFormProps> = (props) => {
    const classes = useStyles();

    const { theme } = useReduxSelector((state) => state.themeState);

    const { t } = useTranslation();

    const {
        supplierPrice,
        supplierNotes,
        currency,
        submit,
        deadlineHasPassed,
        files,
        setFiles,
        removeFile,
        fileIsUploading,
        setFileIsUploading,
    } = props;

    return (
        <Formik
            enableReinitialize
            initialValues={{
                supplierPrice: supplierPrice ?? 0,
                supplierNotes: supplierNotes ?? "",
            }}
            validationSchema={validationSchema(t)}
            onSubmit={(values) => submit(parseFloat(values.supplierPrice.toString()), values.supplierNotes)}
        >
            {({ errors }) => (
                <Form className={classes.form}>
                    <TextField
                        testid="supplier-response-price"
                        label={t("supplierResponse.supplierPrice.label")}
                        tooltip={t("supplierResponse.tooltips.supplierPrice")}
                        name="supplierPrice"
                        type="price"
                        currencyType={currency}
                        required
                        disabled={deadlineHasPassed}
                    />
                    <div className={classes.documentUpload}>
                        <DisplayFileUploadField
                            testid="project-brief"
                            files={files}
                            canManageFiles={!deadlineHasPassed}
                            removeFile={removeFile}
                            setFiles={setFiles}
                            fileIsUploading={fileIsUploading}
                            setFileIsUploading={setFileIsUploading}
                        />
                    </div>
                    <RichTextEditorField
                        testid="supplier-response-submission-additional-information"
                        label={t("supplierResponse.supplierNotes.label")}
                        tooltip={t("supplierResponse.tooltips.supplierNotes")}
                        name="supplierNotes"
                        height={200}
                        disabled={deadlineHasPassed}
                        joditLicense={theme.joditLicence}
                    />
                    {deadlineHasPassed && (
                        <Copy className={classes.deadlineExpired}>{t("projectResponse.deadlineExpired.default")}</Copy>
                    )}
                    <div className={classes.buttonControl}>
                        <Button
                            testid="supplier-response-submit-button"
                            label={t("supplierResponse.submitQuote")}
                            type="submit"
                            size="large"
                            disabled={!isEmpty(errors) || deadlineHasPassed || fileIsUploading}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default SupplierResponseForm;
